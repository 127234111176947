:root {
  --primary: #668DC8;
  --darker-primary: hsla(219, 23%, 22%, 1);
  --bg: #19181B;
  --alt-text: #909090;
}

@font-face {
  font-family: Montserrat-Black;
  src: url("./media/fonts/MONTSERRAT-BLACK.TTF");
}

@font-face {
  font-family: Montserrat-Light;
  src: url("./media/fonts/MONTSERRAT-LIGHT.TTF");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("./media/fonts/MONTSERRAT-REGULAR.TTF");
}


@font-face {
  font-family: Montserrat-Medium;
  src: url("./media/fonts/MONTSERRAT-MEDIUM.TTF");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("./media/fonts/MONTSERRAT-BOLD.TTF");
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: var(--bg);
  font-family: Montserrat-Medium;
  justify-content: center;
  color: white;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

.flex {
  display: flex;
  align-items: center;
}

.col {
  flex-direction: column;
}


.hideOnHover:hover > .hideChild {
  opacity: 0;
  transition-duration: .25s;
}

.showOnHover > .show {
  display: flex;
  position: absolute;
  opacity: 0;
}

.showOnHover:hover > .show {
  opacity: 1;
  transition-duration: .25s;
}

.hover {
  cursor: pointer;
  transition-duration: .25s;
}

.hover:hover {
  opacity: 1;
  transition-duration: .25s;
}